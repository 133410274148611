<div class="text-center">
    <h2>Form di registrazione</h2>
</div>

<!-- Material form register -->
<mdb-card>

  <mdb-card-header class="info-color white-text text-center py-4">
    <h5>
      <strong>Sign up</strong>
    </h5>
  </mdb-card-header>

  <!--Card content-->
  <mdb-card-body class="px-lg-5 pt-0">

    <!-- Form -->
    <form class="text-center" style="color: #757575;">

      <div class="form-row">
        <div class="col">
          <!-- First name -->
          <div class="md-form">
            <input type="text" id="materialRegisterFormFirstName" class="form-control" mdbInput>
            <label for="materialRegisterFormFirstName">Nome</label>
          </div>
        </div>
        <div class="col">
          <!-- Last name -->
          <div class="md-form">
            <input type="email" id="materialRegisterFormLastName" class="form-control" mdbInput>
            <label for="materialRegisterFormLastName">Cognome</label>
          </div>
        </div>
      </div>

      <!-- E-mail -->
      <div class="md-form mt-0">
        <input type="email" id="materialRegisterFormEmail" class="form-control" mdbInput>
        <label for="materialRegisterFormEmail">E-mail</label>
      </div>

      <!-- Password -->
      <div class="md-form">
        <input type="password" id="materialRegisterFormPassword" class="form-control" aria-describedby="materialRegisterFormPasswordHelpBlock"
          mdbInput>
        <label for="materialRegisterFormPassword">Password</label>
        <small id="materialRegisterFormPasswordHelpBlock" class="form-text text-muted mb-4">
          Almeno 7 lettere ed una cifra
        </small>
      </div>

      <!-- Phone number -->
      <div class="md-form">
        <input type="text" id="materialRegisterFormPhone" class="form-control" aria-describedby="materialRegisterFormPhoneHelpBlock"
          mdbInput>
        <label for="materialRegisterFormPhone">Telefono cellulare</label>
        <small id="materialRegisterFormPhoneHelpBlock" class="form-text text-muted mb-4">
          Opzionale
        </small>
      </div>

      <!-- Newsletter -->
      <mdb-checkbox>Subscribe to our newsletter</mdb-checkbox>

      <!-- Sign up button -->
      <button mdbBtn color="info" outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect
        type="submit">Sign in</button>

      <!-- Social register -->
      <p>or sign up with:</p>

      <a type="button" class="light-blue-text mx-2">
        <mdb-icon fab icon="facebook"></mdb-icon>
      </a>
      <a type="button" class="light-blue-text mx-2">
        <mdb-icon fab icon="twitter"></mdb-icon>
      </a>
      <a type="button" class="light-blue-text mx-2">
        <mdb-icon fab icon="linkedin"></mdb-icon>
      </a>
      <a type="button" class="light-blue-text mx-2">
        <mdb-icon fab icon="heart"></mdb-icon>
      </a>


    </form>
    <!-- Form -->

  </mdb-card-body>

</mdb-card>
<!-- Material form register -->