<div class="text-center">
    <h2>Accedi</h2>
</div>
<!-- Material form login -->
<mdb-card>

    <mdb-card-header class="info-color white-text text-center py-4">
      <h5>
        <strong>Accedi</strong>
      </h5>
    </mdb-card-header>
  
  
    <!--Card content-->
    <mdb-card-body class="px-lg-5 pt-0">
  
      <!-- Form -->
      <form class="text-center" style="color: #757575;">
  
        <!-- Email -->
        <div class="md-form">
          <input type="email" id="materialLoginFormEmail" class="form-control" mdbInput>
          <label for="materialLoginFormEmail">E-mail</label>
        </div>
  
        <!-- Password -->
        <div class="md-form">
          <input type="password" id="materialLoginFormPassword" class="form-control" mdbInput>
          <label for="materialLoginFormPassword">Password</label>
        </div>
  
        <div class="d-flex justify-content-around">
          <div>
            <!-- Forgot password -->
            <a href="">Hai dimenticato la password?</a>
          </div>
        </div>
  
        <!-- Sign in button -->
        <button mdbBtn color="info" outline="true" rounded="true" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect
          type="submit">Sign in</button>
  
        <!-- Register -->
        <p>Non sei registrato?
          <a [routerLink] ="['registrati']" >Registrati</a>
        </p>
  
        <!-- Social login -->
        <p>oppure accedi tramite:</p>
        <a type="button" mdbBtn floating="true" color="fb" size="sm">
          <mdb-icon fab icon="facebook"></mdb-icon>
        </a>
        <a type="button" mdbBtn floating="true" color="tw" size="sm">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a>
        <a type="button" mdbBtn floating="true" color="li" size="sm">
          <mdb-icon fab icon="linkedin"></mdb-icon>
        </a>
        <a type="button" mdbBtn floating="true" color="git" size="sm">
          <mdb-icon fab icon="github"></mdb-icon>
        </a>
  
      </form>
      <!-- Form -->
  
    </mdb-card-body>
  
  </mdb-card>
  <!-- Material form login -->