import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { LoginUtenteComponent } from './login-utente/login-utente.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { RegistratiComponent } from './registrati/registrati.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { NewsComponent } from './news/news.component';
import { VideoComponent } from './video/video.component';
import { ComunicaComponent } from './comunica/comunica.component';
import { CollaboraComponent } from './collabora/collabora.component';
import { HomeComponent } from './home/home.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginUtenteComponent,
    ChiSiamoComponent,
    RegistratiComponent,
    ManifestoComponent,
    NewsComponent,
    VideoComponent,
    ComunicaComponent,
    CollaboraComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent]
})
export class AppModule { }
