<div class="text-center">
    <h2>Collabora con noi</h2>
</div>


<!-- Jumbotron -->
<mdb-card style="background-image: url('https://mdbootstrap.com/img/Photos/Others/forest2.jpg')">
  <div class="text-white text-center rgba-stylish-strong py-5 px-4">
    <div class="py-5">

      <!-- Content -->
      <h5 class="h5 orange-text"><mdb-icon fas icon="camera-retro"></mdb-icon> Photography</h5>
      <h2 class="card-title h2 my-4 py-2">Jumbotron with image overlay</h2>
      <p class="mb-4 pb-2 px-md-5 mx-md-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur obcaecati vero aliquid libero doloribus ad, unde tempora maiores, ullam, modi qui quidem minima debitis perferendis vitae cumque et quo impedit.</p>
      <a mdbBtn gradient="peach" mdbWavesEffect> <mdb-icon fas icon="clone" class="left"></mdb-icon> View project</a>

    </div>
  </div>
</mdb-card>
<!-- Jumbotron -->    