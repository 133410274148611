<div class="text-center">
    <h2>Comunica con noi</h2>
</div>
<div class="spazio"></div>
<div class="card-deck">
<mdb-card class="text-white mb-3" bgColor="bg-info" style="max-width: 20rem">
  <mdb-card-header>Scrivici su Facebook</mdb-card-header>
  <mdb-card-body>
    <mdb-card-title>
      <button type="button" mdbBtn size="lg" color="fb" class="waves-light" mdbWavesEffect><i class="fab fa-facebook-f left"></i>Facebook</button>
    </mdb-card-title>
    <mdb-card-text class="text-white">Contatta i nostri social managers per ...</mdb-card-text>
    
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3" bgColor="bg-danger" style="max-width: 20rem">
    <mdb-card-header>Guarda i nostri video su youtube!</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <button type="button" mdbBtn size="lg" color="yt" class="waves-light" mdbWavesEffect><i class="fab fa-youtube left"></i>Youtube</button>
      </mdb-card-title>
      <mdb-card-text class="text-white">Lascia feedback dei nostri video sul nostro canale youtube (. . .)</mdb-card-text>
    </mdb-card-body>
  </mdb-card>
  <mdb-card class="text-white mb-3" bgColor="bg-dark" style="max-width: 20rem">
    <mdb-card-header>Scrivici tramite email</mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <button type="button" mdbBtn size="lg" color="email" class="waves-light" mdbWavesEffect><i class="fas fa-envelope left"></i>Email</button>      </mdb-card-title>
      <mdb-card-text class="text-white">Contattaci tramite email per...</mdb-card-text>
      
    </mdb-card-body>
  </mdb-card>
</div>
