<div class="text-center">
    <h2>Notizie</h2>
</div>
<section class="my-5">

  <!-- Section heading -->
  <h2 class="h1-responsive font-weight-bold text-center my-5">Recent posts</h2>
  <!-- Section description -->
  <p class="text-center w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit
    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
    qui officia deserunt mollit anim id est laborum.</p>

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-lg-5">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img%20(27).jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-7">

      <!-- Category -->
      <a href="#!" class="green-text">
        <h6 class="font-weight-bold mb-3"><mdb-icon fas icon="utensils" class="pr-2"></mdb-icon>Food</h6>
      </a>
      <!-- Post title -->
      <h3 class="font-weight-bold mb-3"><strong>Title of the news</strong></h3>
      <!-- Excerpt -->
      <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
        placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus et aut officiis debitis.</p>
      <!-- Post data -->
      <p>by <a><strong>Carine Fox</strong></a>, 19/08/2018</p>
      <!-- Read more button -->
      <a mdbBtn color="success" mdbWavesEffect>Read more</a>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

  <hr class="my-5">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-lg-7">

      <!-- Category -->
      <a href="#!" class="pink-text">
        <h6 class="font-weight-bold mb-3"><mdb-icon far icon="image" class="pr-2"></mdb-icon>Lifestyle</h6>
      </a>
      <!-- Post title -->
      <h3 class="font-weight-bold mb-3"><strong>Title of the news</strong></h3>
      <!-- Excerpt -->
      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
        deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
        provident.</p>
      <!-- Post data -->
      <p>by <a><strong>Carine Fox</strong></a>, 14/08/2018</p>
      <!-- Read more button -->
      <a mdbBtn color="pink" mdbWavesEffect class="mb-lg-0 mb-4">Read more</a>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-5">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img%20(34).jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

  <hr class="my-5">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-lg-5">

      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img (28).jpg" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-7">

      <!-- Category -->
      <a href="#!" class="indigo-text">
        <h6 class="font-weight-bold mb-3"><mdb-icon fas icon="suitcase" class="pr-2"></mdb-icon>Travels</h6>
      </a>
      <!-- Post title -->
      <h3 class="font-weight-bold mb-3"><strong>Title of the news</strong></h3>
      <!-- Excerpt -->
      <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
        magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro qui dolorem ipsum quia sit amet.</p>
      <!-- Post data -->
      <p>by <a><strong>Carine Fox</strong></a>, 11/08/2018</p>
      <!-- Read more button -->
      <a mdbBtn color="indigo" mdbWavesEffect>Read more</a>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</section>
<div class="row">
    <div class="col-lg-4 col-md-6">
        <!--Card-->
        <mdb-card cascade="true" wider="true">
    
          <div class="view view-cascade gradient-card-header blue-gradient">
            <h2 class="card-header-title mb-2">Titolo notizia 1</h2>
            <p class="">
              <mdb-icon far icon="calendar"></mdb-icon> 2 gennaio 2021</p>
    
          </div>
    
          <mdb-card-body cascade="true" class="text-center">
    
            <mdb-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus, ex, recusandae. Facere modi
              sunt, quod
              quibusdam
              dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium veritatis sequi.Ut enim
              ad
              minima
              veniam, quis nostrum.
            </mdb-card-text>
    
            <a href="link_della_notizia" class="blue-text mt-1 d-flex flex-row-reverse">
              <h5 class="waves-effect p-2" mdbWavesEffect>Continua a leggere
                <mdb-icon fas icon="chevron-right"></mdb-icon>
              </h5>
            </a>
    
          </mdb-card-body>
          <!--/.Card content-->
    
        </mdb-card>
        <!--/.Card-->
      </div>
    <div class="col-lg-4 col-md-6">
      <!--Card-->
      <mdb-card cascade="true" wider="true">
  
        <div class="view view-cascade gradient-card-header blue-gradient">
          <h2 class="card-header-title mb-2">Titolo notizia 2</h2>
          <p class="">
            <mdb-icon far icon="calendar"></mdb-icon> 1 gennaio 2021</p>
  
        </div>
  
        <mdb-card-body cascade="true" class="text-center">
  
          <mdb-card-text>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus, ex, recusandae. Facere modi
            sunt, quod
            quibusdam
            dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium veritatis sequi.Ut enim
            ad
            minima
            veniam, quis nostrum.
          </mdb-card-text>
  
          <a href="#" class="blue-text mt-1 d-flex flex-row-reverse">
            <h5 class="waves-effect p-2" mdbWavesEffect>Continua a leggere
              <mdb-icon fas icon="chevron-right"></mdb-icon>
            </h5>
          </a>
  
        </mdb-card-body>
        <!--/.Card content-->
  
      </mdb-card>
      <!--/.Card-->
    </div>
  
    <div class="col-lg-4 col-md-6">
        <!--Card-->
        <mdb-card cascade="true" wider="true">
    
        <div class="view view-cascade gradient-card-header blue-gradient">
            <h2 class="card-header-title mb-2">Titolo notizia 3</h2>
            <p class="">
            <mdb-icon far icon="calendar"></mdb-icon> 29 dicembre 2020</p>
        </div>
    
          <mdb-card-body cascade="true" class="text-center">
    
            <mdb-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus, ex, recusandae. Facere modi
              sunt, quod
              quibusdam
              dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium veritatis sequi.Ut enim
              ad
              minima
              veniam, quis nostrum.
            </mdb-card-text>
    
            <a href="#" class="blue-text mt-1 d-flex flex-row-reverse">
              <h5 class="waves-effect p-2" mdbWavesEffect>Continua a leggere
                <mdb-icon fas icon="chevron-right"></mdb-icon>
              </h5>
            </a>
    
          </mdb-card-body>
          <!--/.Card content-->
    
        </mdb-card>
        <!--/.Card-->
      </div>
  </div>
  

  <!-- News jumbotron -->
<div class="jumbotron text-center hoverable p-4">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-md-4 offset-md-1 mx-3 my-3">

      <!-- Featured image -->
      <div class="view overlay waves-light" mdbWavesEffect>
        <img src="https://mdbootstrap.com/img/Photos/Others/laptop-sm.jpg" class="img-fluid" alt="Sample image for first version of blog listing">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-md-7 text-md-left ml-3 mt-3">

      <!-- Excerpt -->
      <a href="#!" class="green-text">
        <h6 class="h6 pb-1"><mdb-icon fas icon="desktop" class="pr-1"></mdb-icon> Work</h6>
      </a>

      <h4 class="h4 mb-4">This is title of the news</h4>

      <p class="font-weight-normal">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam
        rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.</p>
      <p class="font-weight-normal">by <a><strong>Carine Fox</strong></a>, 19/08/2016</p>

      <a mdbBtn color="success" mdbWavesEffect>Read more</a>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</div>
<!-- News jumbotron -->