<div class="text-center">
    <h2>Social e info</h2>
</div>
<div class="row">
    <div class="col-md-4">
      <!--Card-->
      <mdb-card cascade="true" wider="true">
  
        <!--Card image-->
        <div class="view view-cascade overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="https://mdbootstrap.com/img/Photos/Horizontal/People/6-col/img%20%283%29.jpg"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight"></div>
          </a>
        </div>
        <!--/Card image-->
  
        <mdb-card-body cascade="true" class="text-center">
          <!--Card content-->
          <!--Title-->
          <h4 class="card-title">
            <strong>Facebook</strong>
          </h4>

  
          <mdb-card-text>
            <button type="button" mdbBtn color="fb" class="waves-light" mdbWavesEffect><mdb-icon fab icon="facebook-f"></mdb-icon></button>

          
        </mdb-card-text>
        <button type="button" mdbBtn color="tw" class=" waves-light" mdbWavesEffect><mdb-icon fab icon="twitter"></mdb-icon></button>
          <button type="button" mdbBtn color="email" class=" waves-light" mdbWavesEffect><mdb-icon fas icon="envelope"></mdb-icon></button>
  
        </mdb-card-body>
        <!--/.Card content-->
  
      </mdb-card>
      <!--/.Card-->
    </div>
    <div class="col-md-4 text-center">
        <h2>Seguici sui nostri social e scoprirai vantaggi strepitosi!</h2>
        <h5>testo esempio</h5>
    </div>

</div>


<div class="spazio"></div>




<!-- News jumbotron -->
<div class="jumbotron text-center hoverable p-4">

  <!-- Grid row -->
  <div class="row">

    <!-- Grid column -->
    <div class="col-md-4 offset-md-1 mx-3 my-3">

      <!-- Featured image -->
      <div class="view overlay waves-light" mdbWavesEffect>
        <img src="https://mdbootstrap.com/img/Photos/Others/laptop-sm.jpg" class="img-fluid" alt="Sample image for first version of blog listing">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-md-7 text-md-left ml-3 mt-3">

      <!-- Excerpt -->
      <a href="#!" class="green-text">
        <h6 class="h6 pb-1"><mdb-icon fas icon="desktop" class="pr-1"></mdb-icon> Work</h6>
      </a>

      <h4 class="h4 mb-4">This is title of the news</h4>

      <p class="font-weight-normal">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam
        rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.</p>
      <p class="font-weight-normal">by <a><strong>Carine Fox</strong></a>, 19/08/2016</p>

      <a mdbBtn color="success" mdbWavesEffect>Read more</a>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

</div>
<!-- News jumbotron -->