import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginUtenteComponent } from './login-utente/login-utente.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { RegistratiComponent } from './registrati/registrati.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { NewsComponent } from './news/news.component';
import { VideoComponent } from './video/video.component';
import { ComunicaComponent } from './comunica/comunica.component';
import { CollaboraComponent } from './collabora/collabora.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'home' , component: HomeComponent},
  { path: 'chi-siamo' , component: ChiSiamoComponent},
  { path: 'comunica', component: ComunicaComponent },
  { path: 'collabora', component: CollaboraComponent },
  { path: 'login-utente' , component: LoginUtenteComponent},
  { path: 'manifesto', component: ManifestoComponent },
  { path: 'news', component: NewsComponent },
  { path: 'registrati', component: RegistratiComponent},
  { path: 'video', component: VideoComponent },
  { path: '*', component: HomeComponent},
  { path: '**', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
