<title>Associazione Eracle</title>

<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark unique-color ie-nav fixed-top" [containerInside]="false">

  <mdb-navbar-brand><img src="/assets/img/logoimm.png"  width="80px"></mdb-navbar-brand>

  <links>
 
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active waves-light">
        <a class="nav-link" [routerLink] = "['home']" mdbWavesEffect>Home</a>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" [routerLink] = "['chi-siamo']" >Chi siamo</a>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" [routerLink] = "['collabora']" >Collabora</a>
      </li>
      <li class="nav-item waves-light" mdbWavesEffect>
        <a class="nav-link" [routerLink] = "['comunica']" >Comunica</a>
      </li>
      <li class="nav-item" mdbWavesEffect>
        <a class="nav-link waves-light" [routerLink] ="['registrati']" mdbWavesEffect >Registrati</a>
      </li>
        <!-- Dropdown -->
      <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            I nostri Contenuti<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" [routerLink] ="['video']" mdbWavesEffect>Video</a>
            <a class="dropdown-item waves-light" [routerLink] ="['news']" mdbWavesEffect>News</a>
            <a class="dropdown-item waves-light" [routerLink] ="['manifesto']" mdbWavesEffect>Manifesto</a>
          </div>
      </li>
    </ul>
    <ul class="nav navbar-nav nav-flex-icons ml-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink] ="['login-utente']" ><i class="fas fa-user"></i> <span class="clearfix d-none d-sm-inline-block">Account</span></a>
      </li>
    </ul>
      <!-- Links -->
  </links>

</mdb-navbar>
<!--/.Navbar-->

<!-- Main -->
<div class="spazio"></div>

<div class="container" role="main">

  <router-outlet></router-outlet>

</div>

<div class="spazio"></div>
<!-- Footer -->
<footer class="page-footer font-small teal pt-4 unique-color">
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col">
        <h5 class="text-uppercase font-weight-bold">Footer </h5>
        <p>Associazione Eracle tm</p>
      </div>
      
    </div>
  </div>

</footer>
