<div class="text-center">
    <strong><h2>I nostri video:</h2></strong>
    <h4>Testimonial, tutorial, etc.</h4>
</div>

<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
  <mdb-carousel-item>
    <video class="video-fluid" autoplay loop muted>
      <source src="https://mdbootstrap.com/img/video/Tropical.mp4" type="video/mp4" />
    </video>
    <div class="carousel-caption">
      <div class="animated fadeInDown">
        <h3 class="h3-responsive">Light mask</h3>
        <p>testo esempio</p>
      </div>
    </div>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <video class="video-fluid" autoplay loop muted>
      <source src="https://mdbootstrap.com/img/video/forest.mp4" type="video/mp4" />
    </video>
  </mdb-carousel-item>
  <mdb-carousel-item>
    <video class="video-fluid" autoplay loop muted>
      <source src="https://mdbootstrap.com/img/video/Tropical.mp4" type="video/mp4" />
    </video>
  </mdb-carousel-item>
</mdb-carousel>